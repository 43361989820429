import { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import Card from '../components/card'
import SmallCard from '../components/smallCard'
import QRCode from 'qrcode.react'
import TopMenu from '../components/topMenu'
import Carousel from 'react-bootstrap/Carousel'
import Form from "react-bootstrap/Form"
import LoaderButton from '../components/loaderButton'
import withRouter from '../components/withRouter'
import InjectedCheckoutForm from '../components/checkoutForm'
import InjectedPayConfirm from '../components/payConfirm'
import { FaArrowLeft, FaMapMarkedAlt, FaHeart, FaRegQuestionCircle, FaStar, FaRegGrinStars, FaRegStar, FaUser, FaUserAltSlash, FaTheaterMasks, FaPersonBooth, FaCcStripe } from 'react-icons/fa'
import { FaPeopleGroup, FaKitchenSet, FaPhone, FaFax } from 'react-icons/fa6'
import { LuCalendarClock, LuCopy, LuCopyCheck } from 'react-icons/lu'
import { BsCalendarFill, BsClockHistory, BsCalendar2Check, BsCalendar2, BsQrCodeScan, BsQrCode } from 'react-icons/bs'
import { BiBookmarkHeart, BiBookmarkPlus } from 'react-icons/bi'
import { FcInvite, FcViewDetails } from 'react-icons/fc'
import { GiPartyPopper, GiSoundOn, GiDrumKit, GiMusicalNotes, GiVikingLonghouse, GiLoveSong, GiJuggler, GiTicket, GiThreeFriends } from 'react-icons/gi'
import { IoPeople } from 'react-icons/io5'
import { HiPaintBrush } from 'react-icons/hi2'
import { AiOutlinePicture } from 'react-icons/ai'
import { SiDiscogs, SiSnapcraft } from 'react-icons/si'
import { PiGuitarThin, PiTelevisionThin } from 'react-icons/pi'
import { RiQuestionAnswerLine, RiSecurePaymentFill, RiMailSendLine } from 'react-icons/ri'
import { MdEmail, MdOutlineCreditCardOff, MdSecurityUpdateGood } from 'react-icons/md'
import Logo from '../images/GigLoca_Logo_Light.png'
import { getAddress, temp } from '../helpers'
import ShareFB from '../components/share-fb'
import ShareX from '../components/share-x'
import ShareReddit from '../components/share-reddit'
import ShareWhat from '../components/share-what'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import './node.css'
const stripePromise = loadStripe("pk_test_51Q2IBjRs36zlCPrA7EwAHXr5VywW254lr8BSUJ5bSBy31yZrtTA5HEwm6PVXUL7JSc1NpUjhLFd0ypwvFk64XVke00nQBQW3mj");

class Event extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isSaving: false,
            venue: null,
            data: null,
            copied: false,
            displaySettings: false,
            displayRate: false,
            displayMsg: false,
            msg: '',
            id: null,
            bounce: false,
            refresh: false,
            stars: null,
            more: null,
            comments: '',
            invite: '',
            userFailed: false,
            userPassed: false,
            lastInvite: '',
            displayTixForm: false,
            groups: [],
            clientSecret: null,
            dpmCheckerLink: null,
            displayPay: false,
            isPaid: false,
            paymentStatus: null,
            paymentId: '',
            displayPaymentStatus: false,
            rid: null,
            remaining: 10,
            emailSel: false,
            shared: false
        }

        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleVenueSelect = this.handleVenueSelect.bind(this)
        this.handleContributorSelect = this.handleContributorSelect.bind(this)
        this.handleFollow = this.handleFollow.bind(this)
        this.handleInterest = this.handleInterest.bind(this)
        this.handleAttending = this.handleAttending.bind(this)
    }

    componentDidMount() {
        const { path, search } = this.props
        const id = path.id || null
        const queryParams = new URLSearchParams(search.search)
        const clientSecret = queryParams.get('payment_intent_client_secret') || null
        const paymentId = queryParams.get('payment_intent') || null
        const paymentStatus = queryParams.get('redirect_status') || null
        const isPaid = paymentStatus ? true : false
        this.setState({ refresh: queryParams.get('refresh') || false, clientSecret, paymentId, paymentStatus, isPaid })
        if (id) {
            if (this.props.myLoc) {
                this.getData(id, this.props.myLoc)
            } else {
                const gl = navigator.geolocation
                gl.getCurrentPosition(position => {
                    this.getData(id, { lat: position.coords.latitude, lng: position.coords.longitude })
                    this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                }, err => {
                    this.getData(id)
                })
            }
        } else this.handleNavigate('')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log('Update')
        if (prevState.remaining > 0 && this.state.remaining === 0 && this.state.rid) {
            //console.log('Clear Interval!!!')
            clearInterval(this.state.rid)
            this.setState({ rid: null, displayPay: false, msg: 'Purchase time has expired and your ticket(s) are abandoned. Please try your purchase again.', displayMsg: true })
        }
    }

    componentWillUnmount() {
        //console.log('Unmount')
        if (this.state.rid) clearInterval(this.state.rid)
    }

    handleNavigate(page) {
        if (this.state.id && page !== `/event/${this.state.id}`) this.props.onAddHistory(`/event/${this.state.id}`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack(this.state.refresh)
    }

    handleCopy() {
        this.setState({ copied: true })
        navigator.clipboard.writeText(getAddress(this.state.venue.address))
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        this.setState({ displaySettings: false })
        if (mode === 'contactus') {
            this.handleNavigate('contactus')
        } else {
            if (mode !== 'fan') {
                this.props.setMode(mode)
                this.handleNavigate(`my/${mode}s`)
            }
        }
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleVenueSelect(vid) {
        this.handleNavigate(`venue/${vid}`)
    }

    handleContributorSelect(cid) {
        this.handleNavigate(`contributor/${cid}`)
    }

    handleInterest() {
        if (this.props.isAuthenticated) {
            API.put('lt', `interest/${this.state.id}`, { body: { status: 'interested', local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.setState({ data: { ...this.state.data, iaminterested: this.state.data.iaminterested ? false : true, interested: this.state.data.iaminterested ? this.state.data.interested - 1 : this.state.data.interested + 1, iamattending: false }, refresh: true })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        } else {
            this.setState({ bounce: true })
            setTimeout(() => this.handleNavigate('main'), 3500)
        }
    }

    handleAttending() {
        if (this.props.isAuthenticated) {
            API.put('lt', `interest/${this.state.id}`, { body: { status: 'attending', local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.setState({ data: { ...this.state.data, iamattending: this.state.data.iamattending ? false : true, attending: this.state.data.iamattending ? this.state.data.attending - 1 : this.state.data.attending + 1, iaminterested: false }, refresh: true })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        } else {
            this.setState({ bounce: true })
            setTimeout(() => this.handleNavigate('main'), 3500)
        }
    }

    handleFollow(id) {
        if (this.props.isAuthenticated) {
            API.put('lt', `interest/${id}`, { body: { status: 'following' } })
                .then(resp => {
                    this.setState({ venue: { ...this.state.venue, iamfollowing: this.state.venue.iamfollowing ? false : true, followers: this.state.venue.iamfollowing ? this.state.venue.followers - 1 : (this.state.venue.followers || 0) + 1 }, refresh: true })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        } else {
            this.setState({ bounce: true })
            setTimeout(() => this.handleNavigate('main'), 3500)
        }
    }

    handleRate(stars) {
        this.setState({ displayRate: true, stars: stars ? stars : (this.state.stars || this.state.data.irated || 1) })
    }

    handleChange = event => {
        if (event.target.id === 'comments') {
            this.setState({ comments: event.target.value.replace(/[<>]/g, '').substring(0, 249) })
        } else if (event.target.id === 'invite') {
            this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, "").toLowerCase(), userFailed: false, userPassed: false, emailSel: false })
        }
    }

    handleSaveRate = (event) => {
        event.preventDefault()
        if (this.props.isAuthenticated) {
            this.setState({ isSaving: true })
            API.post('lt', `review`, { body: { node: this.state.data.id, type: 'event', stars: this.state.stars, comments: this.state.comments, local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.setState({ displayRate: false, isSaving: false, refresh: true })
                    this.getData(this.state.data.id, this.props.myLoc || null)
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) {
                        this.handleNavigate('main')
                    } else this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                })
        } else {
            this.setState({ bounce: true })
            setTimeout(() => this.handleNavigate('main'), 3500)
        }

    }

    handleCancel() {
        this.setState({ displayRate: false, stars: this.state.data.irated || null, comments: this.state.data.comments || '' })
    }

    handleMore(more) {
        if (this.state.more === more) {
            this.setState({ more: null })
        } else this.setState({ more })
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    handleInvite() {
        if (this.state.invite.toLowerCase() !== this.state.lastInvite.toLowerCase()) {
            API.post('lt', `invite`, { body: { email: this.state.invite.toLowerCase(), type: 'share', eid: this.state.id, title: this.state.data.title, local: moment().format("YYYY-MM-DD HH:mm") } })
                .then(resp => {
                    this.setState({ userFailed: false, userPassed: true, invite: '', lastInvite: this.state.invite })
                })
                .catch(err => {
                    this.setState({ msg: "Unable to share with user.", userFailed: true, userPassed: false })
                })
        } else this.setState({ msg: "Duplicate user request.", userFailed: true, userPassed: false })
    }

    handleDisplayTix(gidx) {
        this.setState({ displayTixForm: true, gidx })
    }

    handleTixAccept() {
        if (this.state.groups.length > 0) {
            let groups = []
            this.state.groups.forEach((g, gidx) => {
                if (g) groups.push({ id: this.state.data.tix.groups[gidx].id, num: g })
            })
            API.post('lt', `tix/${this.state.id}`, { body: { groups, local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    if (this.state.data.tix.payment) {
                        if (resp.clientSecret && resp.dpmCheckerLink) {
                            this.setState({ remaining: 10, clientSecret: resp.clientSecret, dpmCheckerLink: resp.dpmCheckerLink, displayPay: true })
                            let rid = setInterval(() => {
                                this.setState({ remaining: this.state.remaining - 1 })
                            }, 60000)
                            this.setState({ rid })
                        } else {
                            this.setState({ msg: "Strip Payment Service Failed.  Please Try again.", displayMsg: true, groups: [] })
                            this.getData(this.state.data.id, this.props.myLoc || null)
                        }
                    } else {
                        this.setState({ groups: [] })
                        API.get('lt', 'user')
                            .then(resp => {
                                this.props.setUser(resp.data)
                            })
                            .catch(err => console.log(err))
                        this.getData(this.state.data.id, this.props.myLoc || null)
                    }
                })
                .catch(err => {
                    this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true })
                    this.getData(this.state.data.id, this.props.myLoc || null)
                })
        }
    }

    handleTixCancel() {
        this.setState({ groups: [] })
    }

    handleTixChange = (event, gidx) => {
        let groups = [...this.state.groups]
        groups[gidx] = event.target.value
        this.setState({ groups })
    }

    handleTixSelect() {
        this.handleNavigate(`tix/${this.state.id}`)
    }

    handleTixPaid() {
        const aidx = this.props.user && this.props.user.attending ? this.props.user.attending.findIndex(a => a.id === this.state.id && a.tix) : -1
        if (aidx > -1) {
            API.post('lt', `tix/${this.state.id}`, { body: { status: this.state.paymentStatus, paymentId: this.state.paymentId, tix: this.props.user.attending[aidx].tix.filter(t => t.pending).map(t => t.tid) } })
                .then(resp => {
                    API.get('lt', 'user')
                        .then(resp => {
                            this.props.setUser(resp.data)
                            this.setState({ groups: [], isPaid: false })
                            this.handleNavigate(`event/${this.state.id}`)
                        })
                        .catch(err => {
                            console.log(err)
                            this.setState({ isPaid: false })
                            this.handleNavigate(`event/${this.state.id}`)
                        })
                    this.getData(this.state.data.id, this.props.myLoc || null)
                })
                .catch(err => {
                    this.setState({ msg: err.response && err.response.data && err.response.data.error ? err.response.data.error : err.message, displayMsg: true, isPaid: false, paymentStatus: '', paymentId: '' })
                    this.getData(this.state.data.id, this.props.myLoc || null)
                })
        }
    }

    handleEmailSelected(email) {
        this.setState({ invite: email, emailSel: true })
    }

    handleShareAll() {
        if (!this.state.shared) {
            API.post('lt', `invite`, { body: { type: 'friends', eid: this.state.id, title: this.state.data.title, local: moment().format("YYYY-MM-DD HH:mm") } })
                .then(resp => {
                    this.setState({ userFailed: false, userPassed: true, invite: '', shared: true })
                })
                .catch(err => {
                    this.setState({ msg: "Unable to share with Entourage.", userFailed: true, userPassed: false })
                })
        }
    }

    ratingIsValid() {
        return this.state.stars || this.state.data.irated
    }

    getData(id, coord) {
        const headers = this.props.user && this.props.user.active ? null : { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } }
        API.get('lt', `${this.props.user && this.props.user.active ? 'fan' : ''}event/${id}${coord ? `?lat=${coord.lat}&lng=${coord.lng}` : ''}`, headers)
            .then(resp => {
                if (resp.data.events && resp.data.events[0]) {
                    this.setState({ venue: { ...resp.data, events: null }, data: { ...resp.data.events[0] }, id, stars: resp.data.events[0].irated || null, comments: resp.data.events[0].comments || '' })
                }
                if (this.state.isPaid) this.handleTixPaid()
            })
            .catch(err => {
                if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                else this.handleBack()
            })
    }

    getSelectedStarsArray(s) {
        let stars = s || this.state.stars || 0
        let selected = []
        for (let x = 0; x < stars; x++) {
            selected.push(x + 1)
        }
        return selected
    }

    getUnselectedStarsArray(s) {
        let stars = s || this.state.stars || 1
        let unselected = []
        for (let x = parseInt(stars); x < 5; x++) {
            let s = x + 1
            unselected.push(s)
        }
        return unselected
    }

    getBGIcon() {
        if (this.state.data && this.state.data.summary) {
            switch (this.state.data.summary) {
                case 'Acoustics Band':
                    return <GiSoundOn />
                case 'DJ':
                    return <SiDiscogs />
                case 'Band':
                    return <GiDrumKit />
                case 'Solo Artist':
                    return <PiGuitarThin />
                case 'Tribute Band':
                    return <GiMusicalNotes />
                case 'Group':
                    return <FaPeopleGroup />
                case 'Duet':
                    return <IoPeople />
                case 'Comedian':
                    return <FaTheaterMasks />
                case 'Karaoke':
                    return <GiLoveSong />
                case 'Trivia':
                    return <RiQuestionAnswerLine />
                case 'Performer':
                    return <GiJuggler />
                case 'Booth':
                    return <FaPersonBooth />
                case 'Paint':
                    return <HiPaintBrush />
                case 'Bounce House':
                    return <GiVikingLonghouse />
                case 'Art':
                    return <AiOutlinePicture />
                case 'Crafting':
                    return <SiSnapcraft />
                case 'Tasting':
                    return <FaKitchenSet />
                case 'Watch Party':
                    return <PiTelevisionThin />
                default:
                    return <GiPartyPopper />
            }
        } else return <FaRegQuestionCircle />
    }

    getOpHours() {
        let days = []
        this.state.venue.operationArray.forEach(o => days = [...days, ...o.values.map(v => { return { open: o.start || null, close: o.stop || null, day: v } })])
        return days.map((d, dayidx) => {
            return <div key={`day-${dayidx}`} className='Node-Item'>{`${d.day} ${d.open ? `${moment(`2023-01-01 ${d.open}`).format('h:mm A')} - ${moment(`2023-01-01 ${d.close}`).format('h:mm A')}` : 'Closed'}`}</div>
        })
    }

    getBounce() {
        return <div className='Node-Content'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Pref-Bounce'>For your All-Access Pass to all that Gigloca has to offer, create your account or sign in to Gigloca.</div>
        </div>
    }

    getValidation(field) {
        switch (field) {
            case 'invite':
                return this.state.invite.length > 2 && this.state.invite.indexOf('@') > 0 && this.state.invite.indexOf('.') > 1
            default:
                return false
        }
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getTix() {
        return <div className='Node-Row'>
            <div className='Node-Row Node-Flex-Spread Tix-Header'>
                <div>Tickets</div>
                <div className='Node-Flex'>
                    <div className='Tix-Icon'><GiTicket /></div>
                    <div className='Tix-Icon'>{this.state.data.tix.payment ? <RiSecurePaymentFill /> : <MdOutlineCreditCardOff />}</div>
                    <div className='Tix-Icon'><BsQrCodeScan /></div>
                    <div className='Tix-Icon'>{this.state.data.tix.email && <MdSecurityUpdateGood />}</div>
                </div>
            </div>
            <div className='Node-Row Node-Flex-Wrap'>
                {
                    this.state.data.tix.groups && this.state.data.tix.groups.map((t, tidx) => {
                        let num = []
                        //const aidx = this.props.user && this.props.user.attending ? this.props.user.attending.findIndex(a => a.id === this.state.id && a.tix) : -1
                        //const gcnt = this.props.user.attending[aidx].tix ? this.props.user.attending[aidx].tix.filter(mytix => t.id === mytix.gid && (!mytix.pending || (mytix.pending && )))
                        //let limit = t.limit ? (aidx > -1 ? 0 : null) : null
                        //Note, you can reduce the options for num tix if you look at how many tix a user has
                        const max = t.limit && t.limit < t.remaining ? t.limit : t.remaining
                        if (t.count) {
                            for (let i = 0; i < max; i++) {
                                num.push(i + 1)
                            }
                        }
                        return <div key={`tix-${tidx}`} className='Tix-Container Tix-Container-1'>
                            <div className='Node-Row Node-Flex-Spread'>
                                <div className='Tix-Detail'>{t.label || ''}</div>
                            </div>
                            <div className='Tix-Detail Tix-Count-1'>{t.remainingText || '0'}</div>
                            <div className='Tix-Detail Tix-Limit-1'>{t.limit || 'No'} per-Fan Limit</div>
                            <div className='Tix-Detail Tix-Price-1'>{`$${t.price || '0.00'}`} per Ticket</div>
                            <div className='Tix-Detail Tix-Cat-1'>{t.cat || ''} Section</div>
                            <div className='Tix-Detail Tix-Footer'>
                                <div className='Node-Row'>
                                    <div className='Node-Row' style={{ textAlign: 'center' }}>
                                        <Form.Group className="form-group form-group-lg">
                                            <Form.Label className='Pref-Label'>{`# of ${t.label} Tickets`}</Form.Label>
                                        </Form.Group>
                                        <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', padding: '5px' }} value={this.state.groups && this.state.groups[tidx] ? this.state.groups[tidx] : ''} id={`tixopt-${tidx}`} onChange={(event) => this.handleTixChange(event, tidx)}>
                                            <option value=''>Select...</option>
                                            {
                                                num.map((o, oidx) => {
                                                    return <option key={`nt-${oidx}`} value={o}>{o}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            {!this.state.displayPay && <div className='Node-Row Node-Flex-Spread'>
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleTixAccept()}>Accept</div>
                <div className='Pref-Button Pref-Cancel-Button' onClick={() => this.handleTixCancel()}>Cancel</div>
            </div>}
            {this.state.displayPay && this.state.clientSecret && this.getPay()}
            {stripePromise && this.state.paymentStatus && this.getPayConfirmed()}
        </div>
    }

    getMyTix() {
        const aidx = this.props.user && this.props.user.attending ? this.props.user.attending.findIndex(a => a.id === this.state.id && a.tix) : -1
        if (aidx > -1) {
            let groups = []
            let receipts = []
            this.props.user.attending[aidx].tix && this.props.user.attending[aidx].tix.forEach(t => {
                const gidx = groups.findIndex(g => g.gid === t.gid)
                if (gidx === -1) {
                    groups.push({ gid: t.gid, cnt: 1, label: t.label, cat: t.cat })
                } else groups[gidx].cnt += 1
                let ridx = receipts.indexOf(t.paymentId)
                if (ridx === -1 && !t.pending) receipts.push(t.paymentId)
            })
            return <div>
                <div className='Node-Row Node-Flex-Wrap'>
                    {
                        groups.map((g, gidx) => {
                            return <div key={`tix-${gidx}`} className='MyTix-Container' onClick={() => this.handleTixSelect()}>
                                <div className='Node-Row Tix-Label'>{g.label}</div>
                                <div className='Node-Row MyTix-QR'><BsQrCode /></div>
                                {g.pending && <div className='Node-Row MyTix-QR'>Pending Payment</div>}
                                <div className='Node-Row Node-Flex-Spread Tix-Footer'>
                                    <div className='Node-Row Tix-Label'>{g.cat}</div>
                                    <div className='MyTix-Count'>{g.cnt}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
                {
                    receipts.length > 0 && <div className='Node-Row'>
                        <div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text' style={{ fontSize: '18pt' }}>View Payment Details on <FaCcStripe /></div>
                            <div className='Node-Divider'></div>
                        </div>
                        {receipts.map((r, ridx) => {
                            return <div key={`receipt-${ridx}`} className='Node-Row'><a href={`https://dashboard.stripe.com/test/payments/${r}`} target="_blank" rel="noopener noreferrer" className='Receipt-Container'><div className='Receipt-Icon'><FcViewDetails /></div><div className='Receipt-Text'>{`View Details - ${ridx + 1}`}</div></a></div>
                        })}
                    </div>
                }
            </div>
        }
    }

    getPay() {
        //add the link to dpm whatever below elements
        //<a href={this.state.dpmCheckerLink} target="_blank" rel="noopener noreferrer" >Preview something</a>
        return <div className='Node-Row'>
            <div className='Node-Row Node-Flex-Left'>
                <div className='Node-Divider-Text'>{`${this.state.remaining} Minutes Remaining for Secure Purchase`}</div>
                <div className='Node-Divider'></div>
            </div>
            <Elements stripe={stripePromise} options={{ clientSecret: this.state.clientSecret, appearance: { theme: 'stripe', variables: { colorText: '#152bcb' } } }}>
                <InjectedCheckoutForm return_url={window.location.href} />
            </Elements>
            <div className='Node-Row' style={{ fontSize: '12pt', color: '#fff', fontStyle: 'italic' }}>This Page will Reload To Complete the Secure Transaction</div>
        </div>
    }

    getPayConfirmed() {
        return <div>
            <Elements stripe={stripePromise}>
                <InjectedPayConfirm clientSecret={this.state.clientSecret} />
            </Elements>
        </div>
    }

    render() {
        //console.log(this.state)
        return this.state.data ? <div className='Node-Container Node-Event'>
            <TopMenu
                mode='fan'
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                displaySettings={this.state.displaySettings}
                pref={null} />
            {
                this.state.bounce ? this.getBounce() : <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
                    <div className='Node-Content-2'>
                        {this.state.data.imageArray && this.state.data.imageArray.length > 0 ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={false} touch={true} indicators={true} interval={2000} fade>
                            {
                                this.state.data.imageArray.map((ci, imgidx) => {
                                    return <Carousel.Item key={`ci-${imgidx}`}>
                                        <img src={`https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.data.name || this.state.data.title || ''} />
                                    </Carousel.Item>
                                })
                            }
                        </Carousel></div> : <div className='Node-Row Node-CI Node-BG-Icon'>{this.getBGIcon()}</div>}
                        <div className='Node-Back'><div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div></div>
                        <div className='Node-Content-3'>
                            <div className='Node-Row Node-Flex-Spread'>
                                <div className='Node-Title'>{this.state.data.title ? this.state.data.title : 'Event'}</div>
                                {(this.state.data.attending || this.state.data.interested) && ((this.state.data.attending || 0) + (this.state.data.interested || 0) > 0) ? <div>
                                    <div className='Node-Heart Node-Heart-Followers'><FaHeart /></div>
                                    <div className='Node-Heart-Text'>{(this.state.data.attending || 0) + (this.state.data.interested || 0) > 1000 ? `${(((this.state.data.attending || 0) + (this.state.data.interested || 0)) / 1000).toFixed(1)}k` : (this.state.data.attending || 0) + (this.state.data.interested || 0)}</div>
                                </div> : null}
                            </div>
                            <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Event Schedule</div>
                                <div className='Node-Divider'></div>
                            </div>
                            <div className='Node-Row Node-Flex-Spread'>
                                <div className='Node-Flex'>
                                    <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                                    <div className='Pref-Text'>{this.state.data.startTime ? moment(this.state.data.startTime).format('MMM DD') : ''}</div>
                                </div>
                                <div className='Node-Flex'>
                                    <div className='Node-Addr-Icon'><BsClockHistory /></div>
                                    <div className='Pref-Text'>{this.state.data.startTime ? moment(this.state.data.startTime).format('h:mm A') : ''}</div>
                                </div>
                                <div className='Node-Flex'>
                                    <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                                    <div className='Pref-Text'>{this.state.data.stopTime ? moment(this.state.data.stopTime).format('MMM DD') : ''}</div>
                                </div>
                                <div className='Node-Flex'>
                                    <div className='Node-Addr-Icon'><BsClockHistory /></div>
                                    <div className='Pref-Text'>{this.state.data.startTime ? moment(this.state.data.stopTime).format('h:mm A') : ''}</div>
                                </div>
                            </div>
                            <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Venue</div>
                                <div className='Node-Divider'></div>
                            </div>
                            <div className='Node-Row Node-Summary'>{this.state.venue.name || 'Venue'}</div>
                            {this.state.venue.address && <div className='Node-Row Node-Flex'>
                                <div className='Node-Addr-Icon'><FaMapMarkedAlt /></div>
                                <div className='Node-Addr' onClick={() => this.handleCopy()}>
                                    {this.state.venue.address ? getAddress(this.state.venue.address) : null}
                                    <div className='Node-Addr-Icon'>{this.state.copied ? <LuCopyCheck /> : <LuCopy />}</div>
                                </div>
                            </div>}
                            {this.state.venue.communicationArray && this.state.venue.communicationArray.length > 0 && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Venue Contact Information</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.venue.communicationArray && this.state.venue.communicationArray.length > 0 ? this.state.venue.communicationArray.map((comm, cidx) => {
                                return <div key={`comm-${cidx}`} className='Node-Row Node-Flex My-Link'><div style={{ textDecoration: 'none' }} className='My-Link-Text'>{comm.communicationType === 'Phone' ? <FaPhone /> : (comm.communicationType === 'Fax' ? <FaFax /> : <MdEmail />)} {comm.communicationType === 'Email' ? <a href={`mailto:${comm.contact}`}>{comm.contact}</a> : (comm.communicationType === 'Phone' ? <a href={`tel:${comm.contact.replace(/[\D\s]/g, '')}`}>{comm.contact}</a> : comm.contact)}</div></div>
                            }) : null}
                            <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Summary</div>
                                <div className='Node-Divider'></div>
                            </div>
                            <div className='Node-Row Node-Summary'>{this.state.data.summary || 'summary'}</div>
                            {this.state.data.weather && this.state.data.weather[0] && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Weather Forecast</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.weather && this.state.data.weather[0] && <div className='Node-Row Node-Flex-Left-NoWrap'>
                                <img src={`https://openweathermap.org/img/wn/${this.state.data.weather[0].weather[0].icon}@2x.png`} className='Event-Weather-Icon' alt={this.state.data.weather[0].weather[0].main} />
                                <div className='Event-Weather-Text'>{this.state.data.weather[0].summary} {`(${temp(this.state.data.weather[0].temp.min)}F - ${temp(this.state.data.weather[0].temp.max)}F)`}</div></div>}
                            {this.state.data.details && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Details</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.details && <div className='Node-Row'>
                                <div className='Node-Details'>{this.state.data.details}</div>
                            </div>}
                            {this.state.data.amenities && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Amenities</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.amenities && <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.state.data.amenities.map((am, amidx) => {
                                        return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                                    })
                                }
                            </div>}
                            {this.state.data.restrictions && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Restrictions</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.restrictions && <div className='Node-Row Node-Flex-Left'>
                                {
                                    this.state.data.restrictions.map((am, amidx) => {
                                        return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                                    })
                                }
                            </div>}
                            {this.state.data.stars && this.state.data.stars > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Rating</div>
                                <div className='Node-Divider'></div>
                                <div style={{ marginRight: '5px' }}>
                                    <div className='Node-Stars'><FaStar /></div>
                                    <div className='Node-Stars-Text'>{this.state.data.stars.toFixed(1)}</div>
                                </div>
                            </div> : null}
                            {this.state.data.irated ? <div className='Node-Row Node-Flex-Left'>
                                <div style={{ fontSize: '14pt', marginTop: '-3px' }} className='Node-Divider-Text'><FaRegGrinStars /> I rated</div>
                                <div className='Node-Divider'></div>
                                <div style={{ marginTop: '3px' }} className='Node-Stars Node-Flex-Spread' onClick={() => this.handleRate()}>{this.getSelectedStarsArray().map((ss) => {
                                    return <div key={`irss-${ss}`} className='Node-Stars'><FaStar /></div>
                                })}</div>
                                <div style={{ marginTop: '3px' }} className='Node-Stars Node-Flex-Spread' onClick={() => this.handleRate()}>{this.getUnselectedStarsArray().map((us) => {
                                    return <div key={`irus-${us}`} style={{ color: '#b3b3b3' }} className='Node-Stars'><FaStar /></div>
                                })}</div>
                            </div> : (moment().isAfter(this.state.data.startTime) && moment().isBefore(moment(this.state.data.stopTime).add(1, 'week')) ? <div className='Node-Row Node-Flex-Wrap Rate-Here-Text Rate-Here-Button' onClick={() => this.handleRate()}><div>Rate This Event</div><div><FaRegStar /></div>
                            </div> : null)}
                            {this.state.displayRate && <div className='Rate-Container'>
                                <form onSubmit={this.handleSaveRate}>
                                    <div style={{ borderBottom: '2px solid yellow', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', paddingBottom: '5px' }} className='Node-Row Node-Flex-Spread'>
                                        {this.getSelectedStarsArray().map((ss) => {
                                            return <div key={`rirss-${ss}`} style={{ marginTop: '5px', cursor: 'pointer' }} className='Node-Stars' onClick={() => this.handleRate(ss)}><FaStar /></div>
                                        })}
                                        {this.getUnselectedStarsArray().map((us) => {
                                            return <div key={`rirus-${us}`} style={{ marginTop: '5px', cursor: 'pointer' }} className='Node-Stars' onClick={() => this.handleRate(us)}><FaRegStar /></div>
                                        })}
                                    </div>
                                    <div className='Node-Row'><Form.Group controlId="comments" className="form-group form-group-lg">
                                        <Form.Label className='Pref-Label'>Comments</Form.Label>
                                        <Form.Control
                                            placeholder="Rating Comments"
                                            as="textarea"
                                            value={this.state.comments}
                                            onChange={event => this.handleChange(event)}
                                        />
                                    </Form.Group></div>
                                    <div className='Node-Row Node-Flex-Spread'>
                                        <div style={{ width: '160px', height: '60px' }}><LoaderButton
                                            size="lg"
                                            disabled={!this.ratingIsValid()}
                                            type="submit"
                                            isLoading={this.state.isSaving}
                                            text='Rate'
                                            loadingText='Saving...'
                                            className='Signup-Button'
                                            style={{ margin: '0px', bottom: '0' }}
                                        /></div>
                                        <div style={{ width: '160px', fontSize: '18pt', fontWeight: 'bold' }} className='Pref-Cancel-Button' onClick={() => this.handleCancel()}>Cancel</div>
                                    </div>
                                </form>
                            </div>}
                            {this.state.data.reviews && this.state.data.reviews.length > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Reviews</div>
                                <div className='Node-Divider'></div>
                                <div className='Node-Divider-Button' onClick={() => this.handleMore('reviews')}>{this.state.more === 'reviews' ? 'Less' : 'More'}</div>
                            </div> : null}
                            {this.state.data.reviews && this.state.data.reviews.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'reviews' ? ' My-Reviews-More' : ''}`}>
                                {this.state.data.reviews.sort((a, b) => {
                                    if (moment(a.created).isAfter(moment(b.created))) return -1
                                    if (moment(a.created).isBefore(moment(b.created))) return 1
                                    return 0
                                }).map((rev, ridx) => {
                                    return <div key={`mrev-${ridx}`} className='My-Review-Container'>
                                        <div className='My-Review-Stars'>
                                            {this.getSelectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                                return <div key={`ss-${ss}`} className='Node-Stars'><FaStar /></div>
                                            })}
                                            {this.getUnselectedStarsArray(parseInt(rev.stars || '1')).map((ss) => {
                                                return <div key={`us-${ss}`} style={{ color: '#b3b3b3' }} className='Node-Stars'><FaStar /></div>
                                            })}
                                        </div>
                                        <div className='Node-Row Node-Flex'>
                                            <div style={{ marginRight: '10px' }}>{rev.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${rev.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                            <div className='My-Review-Text'>{rev.comments}</div>
                                            <div style={{ width: '110px', fontSize: '9pt' }} className='My-Review-Text'>{moment(rev.created).format('ll')}</div>
                                        </div>
                                    </div>
                                })}
                            </div> : null}
                            {this.state.data.posts && this.state.data.posts.length > 0 ? <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Posts from {this.state.data.posts.name}</div>
                                <div className='Node-Divider'></div>
                                <div className='Node-Divider-Button' onClick={() => this.handleMore('posts')}>{this.state.more === 'posts' ? 'Less' : 'More'}</div>
                            </div> : null}
                            {this.state.data.posts && this.state.data.posts.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'posts' ? ' My-Reviews-More' : ''}`}>
                                {this.state.data.posts.sort((a, b) => {
                                    if (moment(a.created).isAfter(moment(b.created))) return -1
                                    if (moment(a.created).isBefore(moment(b.created))) return 1
                                    return 0
                                }).map((post, pidx) => {
                                    return <div key={`mpost-${pidx}`} className='My-Review-Container'>
                                        <div className='Node-Row Node-Flex'>
                                            <div style={{ marginRight: '10px' }}>{post.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${post.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                            <div className='My-Review-Text'>{post.post}</div>
                                            <div style={{ width: '110px', fontSize: '9pt' }} className='My-Review-Text'>{moment(post.created).format('ll')}</div>
                                        </div>
                                    </div>
                                })}
                            </div> : null}
                            <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Venue</div>
                                <div className='Node-Divider'></div>
                            </div>
                            <div className='Node-Row Node-Flex-Wrap'><Card data={this.state.venue} type='venue' onSelected={this.handleVenueSelect} onFollow={this.handleFollow} /></div>
                            {this.state.venue.operationArray && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Addr-Icon'><LuCalendarClock /></div>
                                <div className='Node-Divider-Text'>Hours of Operation</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.venue.operationArray && <div className='Node-Row Node-Flex-Left'>
                                {this.getOpHours()}
                            </div>}
                            {this.state.data.nodes && this.state.data.nodes.length > 0 && <div className='Node-Row Node-Flex-Left'>
                                <div className='Node-Divider-Text'>Contributors</div>
                                <div className='Node-Divider'></div>
                            </div>}
                            {this.state.data.nodes && this.state.data.nodes.length > 0 && <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.state.data.nodes.map((node, nidx) => {
                                        return <div key={`cont-${nidx}`} className='Node-Card'><SmallCard type='contributor' data={node} onSelect={this.handleContributorSelect} /></div>
                                    })
                                }
                            </div>}
                            <div className='Node-Row Node-Flex-Spread'>
                                <div className='Event-Icon-Container' onClick={() => this.handleInterest()}>
                                    <div className='Event-Icon'>{this.state.data.iaminterested ? <BiBookmarkHeart /> : <BiBookmarkPlus />}</div>
                                    <div className='Event-Icon-Text'>{this.state.data.iaminterested ? 'Remove Interest' : 'Add Interested'}</div>
                                </div>
                                <div className='Event-Icon-Container' onClick={() => this.handleAttending()}>
                                    <div className='Event-Icon'>{this.state.data.iamattending ? <BsCalendar2Check /> : <span style={{ color: '#b3b3b3' }}><BsCalendar2 /></span>}</div>
                                    <div className='Event-Icon-Text'>{this.state.data.iamattending ? 'Remove Attendance' : 'Attending'}</div>
                                </div>
                            </div>
                            {this.state.id && <div className='Node-Row Node-Flex-Wrap'><QRCode value={`https://gigloca.com/event/${this.state.id}`} /></div>}
                            <Form.Group controlId="invite" style={{ marginLeft: '5px', marginRight: '5px' }} className="form-group form-group-lg">
                                <Form.Label className='Pref-Label Node-Flex-Spread'><div>Share with a Friend</div><div style={{ border: '2px solid #fff', borderRadius: '5px', padding: '0 3px' }} onClick={() => this.handleShareAll()}>Share with Entourage<GiThreeFriends /></div></Form.Label>
                                <div className='Node-Flex'>
                                    <Form.Control
                                        placeholder="User Email"
                                        type="email"
                                        value={this.state.invite}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('invite')}
                                    />
                                    <div className='My-Link-Icon' onClick={() => this.handleInvite()}><RiMailSendLine /></div>
                                    {this.state.userFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : (this.state.userPassed || this.state.shared ? <div className='My-Link-Icon'><FcInvite /></div> : null)}
                                </div>
                            </Form.Group>
                            {!this.state.emailSel && this.props.user.friendArray && this.props.user.friendArray.length > 0 && this.state.invite.length > 0 && (this.props.user.friendArray.findIndex(fa => fa.email.indexOf(this.state.invite) > -1) > -1) && <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.props.user.friendArray.filter(fa => fa.email.indexOf(this.state.invite) > -1).map((f, fidx) => {
                                        return <div key={`email-${fidx}`} className='Node-Email' onClick={() => this.handleEmailSelected(f.email)}>{f.email}</div>
                                    })
                                }
                            </div>}
                            <div className='Node-Row Node-Flex-Wrap'>
                                <ShareFB url={`https://gigloca.com/event/${this.state.id}`} quote={`Join the Fun${this.state.data.name ? ` with: ${this.state.data.name}` : ''}!`} hashtag={'#Gigloca'} size={24} />
                                <ShareX url={`https://gigloca.com/event/${this.state.id}`} quote={`Join the Fun${this.state.data.name ? ` with: ${this.state.data.name}` : ''}!`} hashtags={['#Gigloca', '']} size={24} />
                                <ShareReddit url={`https://gigloca.com/event/${this.state.id}`} title={`Join the Fun${this.state.data.name ? ` with: ${this.state.data.name}` : ''}!`} size={24} />
                                <ShareWhat url={`https://gigloca.com/event/${this.state.id}`} title={`Join the Fun${this.state.data.name ? ` with: ${this.state.data.name}` : ''}!`} size={24} />
                            </div>
                            {this.state.data.tix && this.getTix()}
                            {this.props.user && this.props.user.attending && this.getMyTix()}
                        </div>
                    </div>
                    <div ref={this.PayStatusDiv}></div>
                </div>
            }
            {
                this.state.displayMsg && this.getMessage()
            }
        </div > : <div style={{ padding: '10%' }} className='Empty-Container Node-Venue'><img className='Node-Logo' src={Logo} alt="Gigloca" /></div>
    }
}

export default withRouter(Event)